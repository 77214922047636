const aDrive = (dostoy) => {
    let driveReadHandler = (resp) => {
        resp = resp.charAt(0).toLowerCase();
        switch (resp) {
            case 'r':
                dostoy.println(`General failure reading drive A`);
                dostoy.input('Abort, Retry, Fail? ', driveReadHandler);
                break;
            case 'f':
            case 'a':
                dostoy.println();
                dostoy.println();
                break;
            default:
                dostoy.input('Abort, Retry, Fail? ', driveReadHandler);
                break;
        }
    };
    dostoy.println(`General failure reading drive A`);
    dostoy.input('Abort, Retry, Fail?', driveReadHandler);
}

export default aDrive;