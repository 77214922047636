
const about = `
   _____                  __   ___     _____
  / ___/__  ______  _____/ /_ <  /___ |__  /
  \\__ \\/ / / / __ \\/ ___/ __ \\/ / __ \\ /_ < 
 ___/ / /_/ / / / (__  ) / / / / / / /__/ / 
/____/\\__,_/_/ /_/____/_/ /_/_/_/ /_/____/  
                                            
 

Sunsh1n3 Labs is a development studio working on several products and 
mobile applications. Always trying to find something new and interesting 
to develop and test.  Each member of the team has a voice in what we 
make and differences and opinions are valued.  We have a number of 
positions available if you want to join our team.  Remote work is available
with catered meals on site.  


`;
const logo = `
   _____                  __   ___     _____
  / ___/__  ______  _____/ /_ <  /___ |__  /
  \\__ \\/ / / / __ \\/ ___/ __ \\/ / __ \\ /_ < 
 ___/ / /_/ / / / (__  ) / / / / / / /__/ / 
/____/\\__,_/_/ /_/____/_/ /_/_/_/ /_/____/  `;

export {about, logo};